import './App.css';
import React from 'react';
import Logo from './img/logo_white.png'
import { Route, Routes, Link } from "react-router-dom"
import HomePage from './components/PageComponents/HomePage.jsx'
import TeamPage from './components/PageComponents/TeamPage.jsx'
import Button from './components/Button'

const navigationBar = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '10vh',
  backgroundColor: '#0a1a35',
  color: 'white',
  paddingLeft: '40px',
  paddingRight: '40px',
}

const staticFooter = {
  backgroundColor: '#0a1a35',
  minHeight: '50px',
  width: '100%',
  display: 'flex',
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: "16px",
  justifySelf: "flex-end"
}

function App() {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={navigationBar}>
        <div style={{ marginRight: '30px' }}>
          <img src={Logo} height='60px' width='60px' alt='logo.png' />
        </div>
        <Link to={'/'} style={{ textDecoration: 'none' }}>
          <Button text='Home' handleClick={() => null} curPage={window.location.href === '/home'} />
        </Link>
        {/* <Button text='About Us' handleClick={() => setPage('about')} curPage={page === 'about'} /> */}
        <Link to={'/team'} style={{ textDecoration: 'none' }}>
          <Button text='Our Team' handleClick={() => null } curPage={window.location.href === '/team'} />
        </Link>
        {/* <Button text='Careers' handleClick={() => setPage('career')} curPage={page === 'career'} /> */}
      </div>

      <div>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="*" element={<></>} />
        </Routes>
      </div>

      <div style={staticFooter}>
        Contact us: chronomirror@forteindiedev.com
      </div>
    </div>
  );
}

export default App;
