const card = {
  fontSize: '14px',
  margin: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '10px',
  width: '350px',
  height: '350px',
  backgroundColor: '#afb9c944',
  boxShadow: 'rgb(38, 57, 77) 0px 20px 30px -10px'
}

const cardName = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '24px',
  fontWeight: 'bold',
  width: '100%',
  height: '20%',
  backgroundColor: '#afb9c966',
  borderRadius: '10px 10px 0px 0px'
}

const titleStyle = {
  fontSize: '18px',
  fontWeight: 'bold',
  padding: '10px'
}

const teamImage = {
  width: '200px',
  height: '200px',
  borderRadius: '5px'
}

const teamLink = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '10%',
  borderRadius: '0px 0px 10px 10px',
  backgroundColor: '#afb9c966',
  marginTop: '10px'
}

const teamImg = {
  boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'
}



export default function Card (props) {
  const name = props.name
  const title = props.title
  const imgRef = props.imgRef
  const link = props.link

  return (
    <div style={card}>
      <div style={cardName}>
        {name}
      </div>
      <div style={titleStyle}>
        {title}
      </div>
      <div style={teamImg}>
        <a href={link} target='_blank' rel='noreferrer'>
          <img src={imgRef} alt={name} style={teamImage} />
        </a>
      </div>
      <div style={teamLink}>
        <a href={link} target='_blank' rel='noreferrer'>{link}</a>
      </div>
    </div>
  )
}